/* Stelle sicher, dass box-sizing für alle Elemente eingestellt ist */
*, *::before, *::after {
  box-sizing: border-box;
}

.kontaktformular h1 {
  text-align: center;
  margin-top: 20px;
  font-family: Varelaround, sans-serif;
  margin-top: 50px;
  margin-bottom: 30px;
}

.form-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background: #32343a;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.input-group {
  display: flex;
  gap: 20px;
}

.input-group input {
  flex: 1;
}

.kontaktformular input,
.kontaktformular textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid white;
  border-radius: 4px;
  background: transparent;
  color: white;
  font-size: 16px; /* Setzt die Schriftgröße */
  font-family: Varelaround, sans-serif; 
}

.kontaktformular input::placeholder,
.kontaktformular textarea::placeholder {
  color: white; /* Setzt die Platzhaltertextfarbe auf Weiß */
  opacity: 1; /* Stellt sicher, dass die Platzhalterfarbe vollständig sichtbar ist */
  font-size: 16px; /* Schriftgröße für den Platzhalter */
  font-family: Varelaround, sans-serif;
}

.kontaktformular input:focus,
.kontaktformular textarea:focus {
  outline: 2px solid #19c1c8; /* Helles Pink */
  border-color: #19c1c8; /* Optional: Passt die Border-Farbe beim Fokussieren an */
}

#nachrichtenfeld {
    height: 150px; /* Setze die Höhe nach deinen Bedürfnissen */
  }

.kontaktformular button {
  width: 100px;
  margin: 0 auto;
  display: block;
  padding: 10px;
  background-color: #19c1c8;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px; /* Schriftgröße für den Platzhalter */
  font-family: Varelaround, sans-serif;
}

.kontaktformular button:hover {
  background-color: #19c1c8;
}

.kontaktformular button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .input-group {
    flex-direction: column;
  }

  .input-group input
   {
    width: 100%; 
  }
  .kontaktformular button{
    width: 100px; 
  }
}

  