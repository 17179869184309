.section-3 {
    margin-top: 93px;
  }


  .lynx-content {
    width: 100%;
    max-width: 1080px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 74px auto 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
  }

  .w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .lynx-grid-image-right {
    width: 100%;
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1.2fr;
    align-items: center;
    margin-top: 30px;
  }

  .lynx-grid-image-left {
    width: 100%;
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1.2fr;
    align-items: center;
  }

  .lynx-block-left {
    padding-right: 15%;
  }

  .lynx-heading {
    max-width: 740px;
    color: #1f2022;
    margin-top: 0;
    margin-bottom: 36px;
    font-family: Varelaround, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 120%;
  }

  .lynx-paragraph {
    max-width: 820px;
    color: #797c83;
    margin-bottom: 36px;
    font-family: Varelaround, sans-serif;
    font-size: 30px;
    line-height: 165%;
  }

  .lynx-block-image {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: relative;
  }

  .lynx-image {
    height: 500px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
  }

  @media screen and (max-width: 991px) {
    .lynx-block-image {
        order: 1;
      }

      .lynx-block-left {
        order: 2;
        background-color: #f7f7f7; 
        max-width: 600px; 
        box-sizing: border-box;
        padding: 20px;
        margin-top: -150px; 
        margin-left: auto;
        margin-right: auto;
        z-index: 100; 
        border-radius: 20px; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
      }

    .w-layout-grid, .lynx-grid-image-right, .lynx-grid-image-left {
      grid-template-columns: 1fr; /* Ändere die Spalten zu einer einzelnen Spalte */
      grid-row-gap: 20px; /* Passe den Zeilenabstand für bessere Lesbarkeit an */
    }
  
     .lynx-block-image {
      width: 100%; 
    }
    .lynx-content {
        display: flex; 
        flex-direction: column; 
        width: 100%; 
    }
  }

  @media screen and (max-width: 600px) {
    .lynx-heading {
        font-size: 35px;
      }
      .lynx-paragraph {
        font-size: 25px;
      }
}
  