*, *::before, *::after {
    box-sizing: border-box;
  }

  .section-2 {
    -webkit-text-stroke-color: #19c1c8;
    margin-top: 58px;
    padding: 0px 10px;
  }

  .lynx-content-hero {
    width: 100%;
    max-width: 1392px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .lynx-hero-large {
    position: relative;
    width: 100%;
    min-height: 650px;
    border-radius: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
  }

  .lynx-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1; 
    border-radius: inherit; 
  }

  .lynx-block-hero-large {
    width: 45%;
    background-color: #19c1c8;
    border-radius: 30px 30px 360px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: -34px;
    padding: 40px 40px 40px 43px;
    display: flex;
  }

  .lynx-heading-white {
    max-width: 740px;
    color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: Varelaround, sans-serif;
    font-size: 58px;
    font-weight: 400;
    line-height: 114%;
  }

  .lynx-paragraph-white {
    max-width: 460px;
    color: #fff;
    margin-bottom: 16px;
    font-family: Varelaround, sans-serif;
    font-size: 25px;
    line-height: 165%;
  }


  .list {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    font-size: 30px;
    font-weight: 500;
    margin-top: 20px;
  }

  .text-block {
    color: #fff;
    font-family: Varelaround, sans-serif;
    font-size: 25px;
    font-weight: 400;
  }

@media screen and (max-width: 1390px) {
    .lynx-block-hero-large {
        width: 50%;
        border-bottom-right-radius: 260px;
        margin-left: -12px;
        padding: 70px 40px;
      }
      .lynx-heading-white {
        font-size: 48px;
        margin-bottom: 0px;
      }
      .lynx-paragraph-white {
        margin-bottom: 6px;
        font-size: 25px;
      }

      .list {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .text-block {
        font-size: 25px;
      }
}

@media screen and (max-width: 991px) {
   .lynx-hero-large {
        justify-content: flex-end;
        align-items: flex-start;
      }

      .lynx-block-hero-large {
        width: 60%;
      }

      .lynx-heading-white {
        font-size: 40px;
        margin-bottom: 0px;
      }
      .lynx-paragraph-white {
        margin-bottom: 6px;
        font-size: 25px;
      }

      .list {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .text-block {
        font-size: 25px;
      }
}
@media screen and (max-width: 730px) {
  .lynx-heading-white {
    font-size: 36px;
  }
  .lynx-paragraph-white {
    font-size: 21px;
  }

  .text-block {
    font-size: 21px;
  }
     .lynx-block-hero-large {
       width: 80%;
     }
}
@media screen and (max-width: 600px) {
    .lynx-hero-large {
        min-height: 400px;
        margin-bottom: 200px;
    }

    .lynx-block-hero-large {
        width: 100%;
        border-bottom-right-radius: 140px;
        margin-bottom: -250px;
        margin-left: 0;
        padding: 30px 10px;
    }

    .lynx-heading-white {
        font-size: 34px;
        margin-bottom: 0px;
      }
      .lynx-paragraph-white {
        margin-bottom: 6px;
        font-size: 21px;
      }

      .list {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .text-block {
        font-size: 21px;
      }
}