.section-5 {
    margin-top: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .div-block {
    position: relative;
    max-width: 1392px;
    min-height: 800px;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;

    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Stellt das Bild hinter den Inhalt */
    border-radius: inherit; 
  }

  .div-block-4 {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 27px;
    display: block;
  }

  .list-2 {
    list-style-type: none;
    max-width: 800px;
    margin: auto;
    padding: 10px;
  }

  .heading {
    color: #fff;
    text-align: center;
    font-family: Varelaround, sans-serif;
    font-size: 50px;
    font-weight: 700;
  }

  .div-block-2 {
    min-height: 200px;
    background-color: #dedede;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .w-layout-layout {
    display: flex;
    width: 100%;
    padding: 30px;
    gap: 20px;
  }

  .w-layout-cell {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .team-member-image {
    width: 200px;
    height: 200px;
    object-fit: cover; /* stellt sicher, dass das Bild den Container bedeckt */
    border-radius: 10px; /* abgerundete Ecken wie bei deinem Container */
  }

  .text-block-7 {
    font-family: Varelaround, sans-serif;
    font-size: 40px;
    font-weight: 700;
  }

  .list-3 {
    padding-left: 20px;
    padding: 15px;
  }

  .list-3 div {
    font-size: 20px; /* Beispiel für die Schriftgröße */
    font-family: Varelaround, sans-serif; /* Beispiel für fette Schrift */
    color: #000; /* Beispiel für die Schriftfarbe */
  }

  .text-block-11 {
    font-family: Varelaround, sans-serif;
    font-size: 22px;
  }

  .button-2 {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #19c1c8;
    border-radius: 40px;
    font-family: Varelaround, sans-serif;
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
  }

  @media screen and (max-width: 600px) {
    .w-layout-layout {
      display: grid; /* Grid statt Flexbox verwenden */
      grid-template-columns: 1fr; /* Eine einzige Spalte */
      gap: 20px; /* Abstand zwischen Grid-Elementen */
    }
  
    .w-layout-layout > .w-layout-cell:first-child {
      order: 1; /* Bild zuerst */
    }
  
    /* Verschiebe den .text-block-7 nach oben, indem du ihn aus seinem Container nimmst */
    .w-layout-layout > .div-block-102 > .w-layout-cell:nth-child(1) .text-block-7 {
      grid-row: 1; /* Platziere den Namen in der ersten Zeile */
    }
  
    /* Platziere das Bild in der zweiten Zeile, unmittelbar nach dem Namen */
    .w-layout-layout > .w-layout-cell:first-child .team-member-image {
      grid-row: 2; /* Bild direkt nach dem Namen */
    }
  
    /* Alle anderen Inhalte in div-block-102 fließen natürlich nach dem Bild */
    .w-layout-layout > .div-block-102 {
      grid-row: 3; /* Stichpunkte und Button nach dem Bild */
    }
  
    .team-member-image {
      width: 100%; /* Bild nimmt die volle Breite ein */
      height: auto; /* Höhe passt sich an */
    }
  }
  
  
  