.section-8 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 67px;
    margin-bottom: 86px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
  }

  .div-block-49 {
    color: #e7f1f2;
  }

  .heading-13 {
    color: #000;
    font-family: Varelaround, sans-serif;
    display: block;
  }

  .text-block-107 {
    color: #000;
    font-family: Varelaround, sans-serif;
    font-size: 20px;
    display: inline;
  }

  .heading-14 {
    text-align: center;
    font-family: Varelaround, sans-serif;
    display: block;
  }

  .f-accordian-wrapper-copy {
    width: 100%;
    max-width: 900px;
    grid-row-gap: 24px;
    border: 3px solid #19c1c8;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    padding: 24px;
    display: flex;
  }

  .f-accordian-dropdown {
    width: 98%;
    margin-left: 0;
    margin-right: 0;
    transition: all .3s;
    display: block;
  }

  .f-accordian-toggle {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
    padding: 0;
  }

  .f-accordian-title-wrapper {
    width: 100%;
    white-space: normal;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .f-accordian-title {
    color: #000;
    letter-spacing: -.01em;
    font-family: Varelaround, sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
  }

  .f-accordian-icon {
    width: 24px;
    height: 24px;
    color: #6b7094;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .f-accordian-list {
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    position: static;
    overflow: hidden;
  }

  .f-accordian-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .f-paragraph-small {
    color: #000;
    letter-spacing: -.01em;
    margin-bottom: 0;
    font-family: Varelaround, sans-serif;
    font-size: 20px;
    line-height: 1.8;
  }