h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
  }


  
  a {
    color: #1a1b1f;
    text-decoration: underline;
    transition: opacity .2s;
    display: block;
  }
  
  


.body-3 {
    background-color: #dedede;
  }

  .section-6 {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
  }

  .section-6.main {
    height: 100%;
    background-color: #fbfbfb;
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .content-container {
    width: 100%;
    max-width: 1140px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .main-info-strip-wrap {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    display: flex;
  }

  .section-wrap {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 55px;
    display: flex;
  }

  .section-name-wrap {
    align-items: center;
    display: flex;
  }


  .section-icon-box {
    width: 44px;
    height: 44px;
    background-color: #19c1c8;
    border-radius: 10px;
    flex: none;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    display: flex;
  }

  .h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Varelaround, sans-serif;
  }

  .section-content-wrap {
    width: 100%;
    padding-left: 64px;
  }


  .section-content-wrap.top-margin-small {
    margin-top: 15px;
  }
  
  .section-content-wrap.top-margin-normal {
    width: 100%;
    margin-top: 50px;
    padding-left: 0;
  }

  .list-4 {
    padding-left: 98px;
  }

  .text-block-108 {
    margin-bottom: 15px;
    font-family: Varelaround, sans-serif;
  }

  .contact-info-wrap {
    width: 300px;
    background-color: #fff;
    border-radius: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 80px;
    padding: 35px;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 10vh;
    box-shadow: 1px 1px 60px rgba(0, 0, 0, .06);
  }

  .contact-info-wrap.after-sections {
    display: none;
  }

  .profile-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 4px solid #fff;
    border-radius: 100px;
    margin-bottom: 25px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, .07);
  }

  .under-picture-info {
    width: 100%;
  }

  .name-text {
    text-align: center;
    letter-spacing: -.5px;
    font-family: Varelaround, sans-serif;
    font-size: 21px;
    font-weight: 700;
  }

  .age-text {
    color: #858585;
    text-align: center;
    letter-spacing: -.5px;
    margin-top: 5px;
    font-family: Varelaround, sans-serif;
    font-size: 21px;
    font-weight: 700;
  }



  .contact-links-wrap {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
  }

  .contact-link {
    width: 100%;
    color: #161616;
    align-items: flex-start;
    margin-top: 10px;
    font-weight: 400;
    text-decoration: none;
    transition: all .2s;
    display: flex;
  }

  .contact-link:hover {
    color: #19c1c8;
  }

  .contact-icon {
    background-color: #19c1c8;
    border-radius: 6px;
    margin-top: 3px;
    margin-right: 10px;
    padding: 3px;
  }


  .buttons-wrap {
    width: 100%;
  }

  .download-cv-button {
    width: 100%;
    color: #161616;
    letter-spacing: -.3px;
    text-transform: uppercase;
    border: 3px solid rgba(133, 133, 133, .2);
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2em;
    transition: all .2s;
    display: flex;
  }
  
  .download-cv-button:hover {
    border-color: #19c1c8;
  }

  .button-content-wrap {
    align-items: center;
    display: flex;
  }

  .button-icon {
    width: 23px;
    height: 23px;
    background-color: #19c1c8;
    border-radius: 5px;
    margin-right: 10px;
    padding: 4px;
  }
  .paragraph {
    margin-bottom: 0;
  }
  
  .paragraph.top-margin-small {
    margin-top: auto;
    padding-left: 74px;
    font-family: Varelaround, sans-serif;
  }


  @media screen and (max-width: 900px) {
    .content-container {
      flex-direction: column-reverse; /* Ändert die Reihenfolge und stapelt die Elemente vertikal */
    }
  
    .contact-info-wrap {
      position: static; /* Entfernt die sticky-Positionierung */
      margin-bottom: 20px; /* Fügt Abstand zum nachfolgenden Inhalt hinzu */
      width: auto; /* Passt die Breite an den Container an */
      max-width: 80%; /* Behält die maximale Breite bei, um das Design konsistent zu halten */
      margin: auto; /* Entfernt den linken Abstand */
    }
    .profile-image {
        width: 60%;
        height: auto;
      }
  
    .main-info-strip-wrap, .section-content-wrap {
      width: 100%; /* Stellt sicher, dass der Inhalt die volle Breite nutzt */
    }
  
    .section-content-wrap {
      padding-left: 0; /* Entfernt das linke Padding im Inhalt */
    }
  }
  

  @media screen and (max-width: 500px) {
    .section-icon-box {
        display: none;
    }
    .paragraph.top-margin-small {
        padding-left: 0px;
      }

    .list-4 {
     padding-left: 0px;
     }
  }